<script lang="ts" setup>
const props = defineProps({
	secondCardColor: { type: String, required: true },
	customY: { type: Number },
	customYAction: { type: Number },
})
const { secondCardColor, customY } = toRefs(props)

const slots = useSlots()
const padding = computed(() => (slots.default == undefined ? '' : 'pt-3'))
</script>

<template>
	<div class="flex flex-col-reverse truncate">
		<div
			class="rounded-md transition-all duration-300 ease-out"
			:style="`transform: translateY(${customYAction || -22}px)`"
		>
			<slot name="action"></slot>
		</div>
		<div
			class="rounded-md transition-all duration-300 ease-out"
			:class="secondCardColor + ' ' + padding"
			:style="`transform: translateY(${customY || -12}px)`"
		>
			<AnimationCollapse>
				<slot></slot>
			</AnimationCollapse>
		</div>
		<div class="z-10 truncate rounded-md">
			<slot name="header"></slot>
		</div>
	</div>
</template>
