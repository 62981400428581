import { defineStore } from 'pinia'
import { IdMobileSheets } from '../constants/IdMobileSheets'

type MobileSheetsState = {
    mobileSheet: IdMobileSheets | undefined
    footerHeight: number
}

export const useMobileSheetsStore = defineStore('🚀 mobile sheets store', {
    state: (): MobileSheetsState => ({
        mobileSheet: undefined,
        footerHeight: 0,
    }),
    actions: {
        addMobileSheet(mobileSheet: IdMobileSheets): void {
            this.mobileSheet = mobileSheet
        },
        closeMobileSheet(): void {
            this.mobileSheet = undefined
        },
    },
})
