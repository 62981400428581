<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg';
import { IconSizes } from '~~/src/constants/iconSizes';
import { LinkedRoomType } from '~~/src/submodules/sharedTypes/common/RoomType';

const props = defineProps({
    derivedRoom: { type: Object as PropType<LinkedRoomType>, required: true }
})
const { derivedRoom } = toRefs(props)

const priceText = computed(() => {
    const sign = derivedRoom.value.variationValue < 0 ? '-' : '+'

    return `${sign} ${derivedRoom.value.variationValue} ${sign}`
})
</script>

<template>
    <div class="flex rounded-md truncate bg-dark-blue-100 border border-dark-blue-200">
        <div class="w-52 px-3 py-2 gap-2 bg-dark-blue-200 items-center grid"
            :style="'grid-template-columns: repeat(2, auto);'">
            <CommonIcon :icon-name="SpSvg.CalendarModLink" :icon-size="IconSizes.S" fill="fill-dark-blue-500" />
            <CommonText :text="TranslationKeys.ADDED_ROOM_CLOSING_PERIOD_TEXT" class="truncate text-dark-blue-500" />
        </div>
        <div class="w-full flex justify-end items-center py-2 px-3">
            <span class="text-sm text-dark-blue-500">{{ priceText }}</span>
        </div>

    </div>
</template>