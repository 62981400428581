<script lang="ts" setup>
import { SpSvg } from '~~/src/autogen/SpSvg';
import { IconSizes } from '~~/src/constants/iconSizes';
import { utilNumber } from '~~/src/utils/UtilNumber';

const props = defineProps({
    firstNumber: { type: Number, required: true },
    secondNumber: { type: Number, required: true }
})
const { firstNumber, secondNumber } = toRefs(props)

const percentage = computed(() => utilNumber.comparisonPercentage(firstNumber.value, secondNumber.value))

const textColor = computed(() => percentage.value < 0 ? "text-red-900" : "text-green-900")
const fillColor = computed(() => percentage.value < 0 ? "fill-red-900" : "fill-green-900")
const backgroundColor = computed(() => percentage.value < 0 ? 'bg-red-200 border-red-400' : 'bg-green-200 border-green-400')
const icon = computed(() => percentage.value < 0 ? SpSvg.BasicArrowDown : SpSvg.BasicArrowTop)
const text = computed(() => percentage.value > 0 ? `+ ${utilNumber.toPercentage(percentage.value / 100)}` : utilNumber.toPercentage(percentage.value / 100))
const showText = computed(() => firstNumber.value > 0)
const iconClasses = computed(() => showText.value ? '' : 'mx-2')
</script>

<template>
    <div v-if="firstNumber != 0 && percentage != 0"
        class="flex min-w-[32px] items-center h-5 gap-1 border rounded px-1" :class="backgroundColor">
        <span class="text-xs" :class="textColor" v-if="showText">{{ text }}</span>
        <CommonIcon :fill="fillColor" :icon-name="icon" :icon-size="IconSizes.XS" :class="iconClasses" />
    </div>
</template>