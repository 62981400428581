import { lightFormat } from 'date-fns'
import { trackingConfig } from '../config/TrackingConfig'
import { TrackingMessages } from '../constants/trackingMessages'
import { useAccommodationsStore } from '../store/accommodations'
import { utilDate } from '../utils/utilDate'
import { utilModifiers } from '../utils/utilModifiers'
import { Modifiers } from './../submodules/sharedTypes/common/Modifiers'
import { utilTracking } from './../utils/utilTracking'

const localeToUse = utilDate.toLocale('enUs')

export const trackPriceDetailSheetOpen = (day: Date) => {
	const day_of_the_week = utilDate.getShortDayName(day, localeToUse)

	utilTracking.track(TrackingMessages.PRICE_DETAIL_SHEET_OPEN, {
		day_of_the_week,
	})
}

export const trackPriceDetailShowMore = (day: Date) => {
	const day_of_the_week = utilDate.getShortDayName(day, localeToUse)

	utilTracking.track(TrackingMessages.PRICE_DEATIL_SHOW_MORE, {
		day_of_the_week,
	})
}

export const trackPriceDetailChartTabOpen = (day: Date) => {
	const day_of_the_week = utilDate.getShortDayName(day, localeToUse)

	utilTracking.track(TrackingMessages.PRICE_DETAIL_CHART_TAB_OPEN, {
		day_of_the_week,
	})
}

export const trackPriceDetailSave = (roomTypeId: number, date: Date, modifiers: Modifiers) => {
	const accommodationsStore = useAccommodationsStore()
	const roomType = accommodationsStore.getRoomTypeById(roomTypeId)
	const accommodation = useAccommodationsStore().getAccommodationById(roomType.accommodationId)
	const tweak_type = modifiers.tweak != undefined ? utilModifiers.tweakToTrackingType(modifiers.tweak) : ''

	utilTracking.track(TrackingMessages.SINGLE_PRICE_CUSTOMIZATION, {
		changed_date: lightFormat(date, 'yyyy-MM-dd'),
		single_price_change_acc_country: accommodation?.location?.country,
		days_date_interval_num: 1,
		days_selected_num: 1,
		days_of_the_week: [utilDate.getDayOfWeek(date, trackingConfig.trackingLanguage)],
		selected_rooms: roomTypeId,
		customization_source: 'Sheet',
		customization_type: 'single_room',
		is_frozen_price: modifiers.frozen != undefined,
		is_max_price: modifiers.max != undefined,
		is_min_price: modifiers.min != undefined,
		is_tweak_price: modifiers.tweak != undefined,
		tweak_type,
	})
}
