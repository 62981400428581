<script setup lang="ts">
import { useOverbooking } from '~/hooks/useOverbooking';

const props = defineProps({
    currentOccupancy: { type: Number, required: true }
})
const { currentOccupancy } = toRefs(props)

const { bookingPercentage, isOverbooked, overbookingPercentage } = useOverbooking(currentOccupancy)
</script>

<template>
    <div class=" w-3 h-full truncate bg-white rounded flex flex-col-reverse">
        <div class="w-3 max-h-full bg-light-blue-600" :class="isOverbooked ? 'rounded-bl rounded-br' : 'rounded'"
            :style="`height: ${bookingPercentage}%;`" />
        <div class="w-3 max-h-full rounded-tr rounded-tl bg-yellow-400" :style="`height: ${overbookingPercentage}%;`" />
    </div>
</template>