<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { TextSizes } from '~~/src/constants/textSizes';
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency';
import { utilNumber } from '~~/src/utils/UtilNumber';

const props = defineProps({
    smartPricingPrice: { type: Number, required: true },
    userPrice: { type: Number, required: false },
    currency: { type: Object as PropType<Currency>, required: true }
})
const { smartPricingPrice, userPrice, currency } = toRefs(props)

const isCustomized = computed(() => userPrice?.value != undefined
    && userPrice.value != 0
    && smartPricingPrice.value != userPrice.value)

const headerText = computed(() => isCustomized.value ? TranslationKeys.USER_STRATEGY : TranslationKeys.SMARTPRICING_STRATEGY)
const headerPrice = computed(() => isCustomized.value ? userPrice!.value! : smartPricingPrice.value)
</script>

<template>
    <CommonCollapsableCard second-card-color="bg-alert-400">
        <template v-slot:header>
            <div class="px-4 py-3 justify-between items-center flex bg-alert-200">
                <CommonText :text="headerText" :text-size="TextSizes.BODY_REGULAR" />
                <span class="text-lg font-semibold">
                    {{ utilNumber.toCurrency(headerPrice, currency) }}
                </span>
            </div>
        </template>

        <div v-if="isCustomized">
            <div class="px-4 py-3 justify-between items-center flex">
                <CommonText :text="TranslationKeys.SMARTPRICING_STRATEGY" :text-size="TextSizes.BODY_REGULAR" />
                <span class="text-lg font-semibold">{{ utilNumber.toCurrency(smartPricingPrice, currency) }}</span>
            </div>
        </div>
    </CommonCollapsableCard>
</template>