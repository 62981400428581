<script setup lang="ts">
import { PropType } from 'vue';
import { SpSvg } from '~/autogen/SpSvg';
import { IconSizes } from '~/constants/iconSizes';
import { Event, EventType } from '~/submodules/sharedTypes/common/Event';

const props = defineProps({
    customEvent: {
        type: Object as PropType<Omit<Event, 'eventPeriod' | 'eventDetails'>>, required: true
    }
})
const { customEvent } = toRefs(props)
const text = computed(() => {
    const currentLang = useLocale().currentLocale

    // @ts-ignore
    return customEvent.value.label[currentLang.value] || customEvent.value.label.it || customEvent.value.label.en || customEvent.value.label.de
})
</script>

<template>
    <div class="w-full rounded py-1 px-2 flex gap-2"
        :class="customEvent.eventType === EventType.Event ? 'bg-events-300' : 'bg-festivities-300'">
        <CommonIcon v-if="customEvent.isUserGenerated || customEvent.isUserModified"
            :icon-name="customEvent.isUserGenerated ? SpSvg.CalendarUser : SpSvg.CalendarUserAlt"
            :icon-size="IconSizes.XS" />
        <CommonText :text="({ key: text, replacements: () => [] })" />
    </div>
</template>