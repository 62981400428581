<script setup lang="ts">
import { SpCommonIcon, SpIconSize } from '@dev.smartpricing/sp-vue-components'
import { TranslationKeys } from '~/i18n/TranslationKeys';

const props = defineProps<{
	isOrphanNightFixed: boolean | undefined
}>()
</script>

<template>
	<div
		v-if="props.isOrphanNightFixed"
		class="p-4 mb-3 mt-2 rounded-md flex gap-2 items-center self-stretch shrink-0 bg-secondary-gray-100"
	>
		<SpIcon :name="SpCommonIcon.HalfMoonEmpty" :size="SpIconSize.Small" />
		<SpText :text="useLocale().translate(TranslationKeys.ORPHAN_NIGHTS_CALENDAR_PRICE_DETAIL)" :size="SpTextSize.BodyRegular01" />
	</div>
</template>
