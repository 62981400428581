<script lang="ts" setup>
import { PropType } from 'vue'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { IconSizes } from '~~/src/constants/iconSizes'
import { IdModal } from '~~/src/constants/IdModal'
import { TextSizes } from '~~/src/constants/textSizes'
import { useToggle } from '~~/src/hooks/useToggle'
import { useModalStore } from '~~/src/store/modals'
import { Price } from '~~/src/submodules/sharedTypes/common/Price'
import { utilModifiers } from '~~/src/utils/utilModifiers'
import { utilNumber } from '~~/src/utils/UtilNumber'
import { utilJustifiers } from '~~/src/utils/utilJustifiers'
import { UserflowId } from '~~/src/constants/UserflowId'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { useMobileInfos } from '~~/src/hooks/useMobileInfos'
import { EmitsEnum } from '~~/src/constants/emits'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

const props = defineProps({
	price: { type: Object as PropType<Price> },
	currency: { type: Object as PropType<Currency>, required: true },
	isHardLink: {
		type: Boolean,
		default: false,
	},
	expand: { type: Boolean, default: false },
	accommodationId: { type: String, required: false },
})
const emit = defineEmits([EmitsEnum.Toggle])

const { price, currency, isHardLink, expand, accommodationId } = toRefs(props)
const formattedPrice = computed(() => utilNumber.toCurrency(price!.value!.publishedPrice!, currency.value))

const modalsStore = useModalStore()
const { isMobile } = useMobileInfos()

const { toggle, setToggle, toggleValue } = useToggle(expand.value)
watch(expand, () => setToggle(expand.value))
const onToggle = () => {
	toggle()
	emit(EmitsEnum.Toggle, toggleValue.value)
}

const channelManager = computed(() =>
	accommodationId?.value
		? useAccommodationsStore().getAccommodationById(accommodationId.value)?.cm
		: useI18n().t(TranslationKeys.CHANNEL_MANAGER)
)

const basePrice = computed(() => price?.value?.priceDetails?.pricePrediction.basePrice)
const getTextColor = (val: number) => {
	if (val == undefined) {
		return 'text-white'
	}
	return val < 0 ? 'text-red-400' : 'text-green-400'
}

const displayableJustifiers = computed(() =>
	(price?.value?.priceDetails?.justifiers || []).filter(
		(el) => el.label != undefined && el.label != '' && el.value != 0
	)
)
const displayableModifiers = computed(() => {
	const modifiers = price?.value?.customerParams?.modifiers
	const activeModifiers = price?.value?.customerParams?.activeModifiers
	if (modifiers == undefined || activeModifiers == undefined) {
		return []
	}

	return utilModifiers.toReadableArray(modifiers, activeModifiers, currency.value)
})

const onJustifiersHelp = () => modalsStore.addModal(IdModal.PriceJustifiersHelp)
const priceDerivedText = useLocale().translate(TranslationKeys.PRICE_DERIVED_FROM, [''])
</script>

<template>
	<CommonCollapsableCard second-card-color="bg-main-blue-800 hover:bg-main-blue-900">
		<template v-slot:header>
			<div
				class="flex cursor-pointer items-center justify-between rounded-md bg-main-blue-700 px-4 py-3"
				@click="onToggle"
				:data-id="UserflowId.PublishedPriceRecap"
			>
				<CommonText :text="TranslationKeys.PUBLISHED" :text-size="TextSizes.BODY_REGULAR" class="text-white" />
				<SpTooltip
					:hide-tooltip="price?.isSent"
					hide-cursor
					:text="$t(TranslationKeys['calendar.how-doest-it-work.PRICE_PENDING_INTG_LIMIT'], [channelManager])"
				>
					<div class="flex items-center gap-3">
						<span class="text-lg font-semibold text-white">{{ formattedPrice }}</span>
						<div class="relative h-2 w-2">
							<div
								class="absolute h-full w-full rounded-full"
								:class="price?.isSent ? 'bg-gem-green-800' : 'animate-ping bg-yellow-400'"
							></div>
							<div
								class="relative h-full w-full rounded-full"
								:class="price?.isSent ? 'bg-gem-green-800' : 'bg-yellow-400'"
							></div>
						</div>
					</div>
				</SpTooltip>
			</div>
		</template>

		<div>
			<div
				class="flex cursor-pointer items-center justify-between px-4 py-3"
				@click="onToggle"
				:data-id="UserflowId.JustifiersPriceRecap"
			>
				<div class="flex items-center gap-1">
					<CommonText :text="TranslationKeys.PRICES_JUSTIFIER" :text-size="TextSizes.BODY_REGULAR" class="text-white" />
					<CommonButton v-if="!isMobile" :type="ButtonTypes.GHOST" :size="ButtonSizes.ICON_S" @click="onJustifiersHelp">
						<CommonIcon :icon-name="SpSvg.BasicHelp" :icon-size="IconSizes.XS" fill="fill-white" />
					</CommonButton>
				</div>
				<CommonIcon
					:icon-name="SpSvg.BasicCarretDown"
					fill="fill-white"
					class="transition-all duration-300"
					:class="toggleValue ? 'rotate-180' : ''"
				/>
			</div>

			<AnimationCollapse>
				<div v-if="toggleValue && price?.isRoomTypeIndexed">
					<div class="flex flex-row">
						<!-- link icon -->
						<div class="border-t border-t-main-blue-600 py-3 pl-4">
							<CommonIcon
								:icon-size="IconSizes.XS"
								:icon-name="isHardLink ? SpSvg.CalendarModLink : SpSvg.CalendarModSemiLink"
								fill="fill-white"
							/>
						</div>

						<div class="flex grow flex-col gap-2 border-t border-t-main-blue-600 py-3">
							<!-- price title -->
							<div class="flex items-center justify-between px-4">
								<CommonText
									:text="priceDerivedText as TranslationKey"
									:text-size="TextSizes.BODY_SEMIBOLD"
									class="text-white"
								/>
							</div>
							<!-- derivation info -->
							<div class="flex items-center justify-between px-4">
								<CommonText
									:text="price.indexedPrice?.linkedRoomType.name as TranslationKey"
									:text-size="TextSizes.BODY_REGULAR"
									class="text-main-blue-200"
								/>
								<span class="text-sm text-main-blue-200">
									{{ utilNumber.toCurrency(price.indexedPrice?.linkedRoomTypePredictedPrice, currency) }}
								</span>
							</div>
							<!-- derivation modifier -->
							<div class="flex items-center justify-between px-4">
								<div />
								<span class="text-sm text-main-blue-200">
									{{ utilJustifiers.readableLinkedRoomModifier(price.indexedPrice!.linkedRoomType, currency) }}
								</span>
							</div>
						</div>
					</div>

					<div v-if="displayableModifiers.length > 0" class="flex flex-col border-t border-t-main-blue-600 py-2">
						<div v-for="modifier in displayableModifiers" class="flex items-center justify-between px-4 py-0.5">
							<CommonText
								:text="modifier.key as TranslationKey"
								:text-size="TextSizes.BODY_REGULAR"
								class="text-yellow-300"
							/>
							<span class="text-sm text-yellow-300">{{ modifier.val }}</span>
						</div>
					</div>
				</div>

				<div v-else-if="toggleValue">
					<div
						class="flex items-center justify-between border-t border-t-main-blue-600 px-4 py-3"
						:data-id="UserflowId.BasePriceRecap"
					>
						<CommonText :text="TranslationKeys.BASE_PRICE" :text-size="TextSizes.BODY_SEMIBOLD" class="text-white" />
						<span :class="TextSizes.BODY_SEMIBOLD" class="text-white">
							{{ utilNumber.toCurrency(basePrice, currency) }}</span
						>
					</div>

					<div
						v-if="price?.priceDetails != undefined && displayableJustifiers.length > 0"
						class="flex flex-col border-t border-t-main-blue-600 py-2"
						:data-id="UserflowId.JustifiersDetailPriceRecap"
					>
						<div v-for="justifier in displayableJustifiers" class="flex items-center justify-between px-4 py-0.5">
							<span class="text-sm" :class="getTextColor(justifier.value)">
								{{ utilJustifiers.translateJustifier(justifier) }}
							</span>
							<span class="text-sm" :class="getTextColor(justifier.value)">
								{{ utilJustifiers.readableJustifierValue(justifier, currency) }}
							</span>
						</div>
					</div>

					<div v-if="displayableModifiers.length > 0" class="flex flex-col border-t border-t-main-blue-600 py-2">
						<div v-for="modifier in displayableModifiers" class="flex items-center justify-between px-4 py-0.5">
							<CommonText
								:text="modifier.key as TranslationKey"
								:text-size="TextSizes.BODY_REGULAR"
								class="text-yellow-300"
							/>
							<span class="text-sm text-yellow-300">{{ modifier.val }}</span>
						</div>
					</div>
				</div>
			</AnimationCollapse>
		</div>
	</CommonCollapsableCard>
</template>
